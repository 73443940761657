import React from 'react';
import { useUpdateBulkWorkOrderMutation } from '../../../../../app/services/workOrderSlice';
import {
  Model,
  initialValues,
  validationSchema,
} from '../../../../../formModel/update/status';
import { BackDropLoader } from '../../../../common/loader';
import { Formik } from 'formik';
import { ToastContainer } from 'react-toastify';
import {
  Datepicker,
  InputFieldWithLabel,
  Select,
} from '../../../../common/formElements';
import UpdateArrivalDate from '../../../../common/Dialog/UpdateArrivalDate';

const UpdateContent = ({ setIsOpen, onSubmitBulkUpdate, isLoading }) => {
  const {
    formField: {
      cargoAvailableStatus,
      status,
      arrivalDate,
      departureDate,
      email,
      lastFreeDay,
    },
  } = Model;

  const onFormSubmit = async (values, actions) => {
    onSubmitBulkUpdate(values, actions);
  };

  return (
    <div className="flex flex-col gap-8 justify-between  h-full py-3">
      {isLoading && <BackDropLoader />}

      <Formik
        // enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => onFormSubmit(values, actions)}
      >
        {(formik) => {
          return (
            <form>
              <ToastContainer containerId={'error'} />

              <div className="flex flex-col gap-6 w-[70%] mx-auto">
                <div className="flex gap-3 flex-col w-full ">
                  <Select
                    fieldlabel={cargoAvailableStatus.label}
                    placeholder={cargoAvailableStatus.placeholder}
                    name={cargoAvailableStatus.name}
                    options={[
                      {
                        value: 'receivedAtWarehouse',
                        label: 'Received At Warehouse',
                      },
                      {
                        value: 'inProcessOfCheckIn',
                        label: 'In Process Of Check In',
                      },

                      {
                        value: 'checkInComplete',
                        label: 'Ready for Pick up',
                      },
                    ]}
                  />
                  <Select
                    fieldlabel={status.label}
                    placeholder={status.placeholder}
                    name={status.name}
                    options={[
                      {
                        value: 'placed',
                        label: 'Order Placed',
                      },
                      {
                        value: 'shipped',
                        label: 'Order Shipped',
                      },
                      {
                        value: 'delivered',
                        label: 'Order Delivered',
                      },
                    ]}
                  />
                  <Datepicker
                    fieldlabel={arrivalDate.label}
                    placeholder={arrivalDate.label}
                    name={arrivalDate.name}
                  />
                  <Datepicker
                    fieldlabel={departureDate.label}
                    placeholder={departureDate.label}
                    name={departureDate.name}
                  />
                  <Datepicker
                    fieldlabel={lastFreeDay.label}
                    placeholder={lastFreeDay.label}
                    name={lastFreeDay.name}
                  />
                  <InputFieldWithLabel
                    fieldlabel={email.label}
                    placeholder={email.label}
                    name={email.name}
                  />
                </div>

                <button
                  type="submit"
                  onClick={formik.handleSubmit}
                  className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                >
                  Update
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
      <div className="w-[90%] mx-auto">
        <button
          onClick={() => setIsOpen(false)}
          className="bg-gray-400 w-full py-[0.6em] font-bold rounded-lg hover:bg-gray-500 text-white"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default UpdateContent;
