import React from 'react';
import _ from 'lodash';
import { TableButton } from '../formElements';

const TableData = ({
  onEditItem,
  onDeleteItem,
  onViewItem,
  showEditButton,
  showDeleteButton,
  showViewButton,
  data,
  itemNumber,
  column,
  index,
}) => {
  if (column.name === 'action') {
    return (
      <td
        className={`${column.styles} sticky right-0 bg-white flex-1 justify-center flex gap-1 items-center`}
      >
        {showViewButton && (
          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              onViewItem(data);
            }}
            type="view"
          />
        )}
        {showEditButton && (
          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              onEditItem(data);
            }}
            type="edit"
          />
        )}
        {showDeleteButton && (
          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              onDeleteItem(data);
            }}
            type="delete"
          />
        )}
      </td>
    );
  }

  if (column.name === 'index') {
    return (
      <td className={`${column.styles} sticky left-0 bg-white`}>
        {itemNumber}
      </td>
    );
  }

  const value = _.get(data, column.key);
  console.log({ key: column.key, data });
  const content = column.render ? (
    column.render(value, data)
  ) : (
    <span className="capitalize">{value || ''}</span>
  );

  const cellClasses = [
    column.styles,
    'flex-1 justify-center flex py-1 items-center',
    'min-w-[100px]', // Minimum width to prevent extreme shrinking
    column.wrap
      ? 'whitespace-normal break-words'
      : 'whitespace-nowrap truncate',
  ].join(' ');

  return (
    <td
      // className={`${column.styles} break-words text-wrap text-center flex-wrap flex-1 justify-center flex py-1 items-center min-w-[100px]`}
      className={cellClasses}
    >
      <div
        className={`px-1 w-full ${column.wrap ? 'text-center' : 'text-center'}`}
      >
        {content}
      </div>
    </td>
  );
};

export default TableData;
