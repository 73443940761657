import React, { useEffect, useMemo, useState } from 'react';
import {
  TableBody,
  TableFooter,
  TableHeader,
  TableTopHeader,
} from '../../common/table';
import { useButtonActions, useDebounce, useDialogModel } from '../../../hooks';
import useTableData from '../../../hooks/useTableData';
import { packagecolumns as columns } from './config';
import { useGetPackagesQuery } from '../../../app/services/packageSlice';
import { Dialog, DeleteDialog } from '../../common/Dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUpdateBulkWorkOrderPackagesMutation } from '../../../app/services/workOrderSlice';
import Filters from './Filters';
import Drawer from '../workOrder/view/UpdateDrawer/Drawer';
import UpdateContent from '../workOrder/view/UpdateDrawer/UpdateContent';

const PackageTable = () => {
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [filters, setFilters] = useState({
    type: null,
    favorite: null,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [searchTrackingId, setSearchTrackingId] = useState('');
  // console.log({ filters });
  // table container
  const [open, openDialog, closeDialog] = useDialogModel();
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    query,
    goToPage,
    setItemsPerPage,
    setSearchQuery,
    setSortQuery,
    sortQuery,
  } = useTableData();

  const {
    currentItem,
    isEditing,
    isDeleting,
    startEditing,
    startDeleting,
    resetActions,
    isAdding,
    startAdding,
    startEditingBulk,
    startViewing,
    isViewing,
  } = useButtonActions();

  const { state } = useLocation();
  window.history.replaceState({}, document.title);
  const debouncedSearchTerm = useDebounce(searchTitle, 200);
  const debouncedSearchTermTrackingId = useDebounce(searchTrackingId, 200);
  const [updateBulkPackagesRequest, updateBulkPackagesRequestQureyResult] =
    useUpdateBulkWorkOrderPackagesMutation();

  const {
    isLoading,
    isFetching,
    refetch,
    data,
    isError,
    error,
    isUninitialized,
    currentData,
    status,
  } = useGetPackagesQuery(
    {
      limit: pageSize,
      page: currentPage,
      sort: `${sortQuery.dir === 'dsc' ? '-' : ''}${sortQuery.field}`,
      type: filters.type,
      favorite: filters.favorite
        ? filters.favorite === 'in-favorites'
          ? true
          : false
        : null,
      debouncedSearchTerm,
      debouncedSearchTermTrackingId,
      searchField: 'title',
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  //   const [deleteRequest, { isLoading: isLoadingDeleteMt }] =
  //     useDeleteWorkOrderMutation()
  //   const [updateBulkRequest, { isLoading: isLoadingUpdateBulkWorkOrder }] =
  //     useUpdateBulkWorkOrderMutation()

  const getFilteredData = useMemo(() => {
    if (isLoading || isFetching) return [];
    if (data?.data?.length > 0) {
      setSelectedRow('');
      return data?.data?.filter((item) => {
        return item;
      });
    } else {
      return [];
    }
  }, [isLoading, isFetching, data?.data]);

  const onRefreshClick = () => {
    refetch();
  };

  const onEditItemClick = (item) => {
    navigate(`/dashboard/work-orders/edit/${item._id}`);
  };
  const onDeleteItemClick = (item) => {
    startDeleting(item);
    openDialog();
  };
  const onViewItemClick = (item) => {
    navigate(`/dashboard/work-orders/view/${item._id}`);
  };

  const handleRowSelect = (rowId) => {
    setSelectedRow((ids) => {
      const updateSelectedRows = selectedRow.includes(rowId)
        ? selectedRow.filter((id) => id !== rowId)
        : [...ids, rowId];
      return updateSelectedRows;
    });
  };

  const onSubmitBulkUpdate = async (values, actions) => {
    // console.log({ values, actions });
    // console.log({ selectedRow });

    const ids = [...selectedRow];
    const data = {
      favourite:
        values.favourite === 'addToFavorite'
          ? true
          : values.favourite === 'removeFromFavorite'
            ? false
            : values.favourite,
      package_check_off: values?.package_check_off,
    };

    console.log({ data });

    try {
      const response = await updateBulkPackagesRequest({
        ids,
        ...data,
      }).unwrap();
      toast.success(response.message, {
        containerId: 'successToastpkg',
        closeOnClick: true,
        toastId: 122,
        autoClose: 1000,
      });
      setIsOpenDrawer(false);
      if (response?.success) {
        actions.resetForm();
        // navigate(`/dashboard/work-orders/view/${id}`, { state: response });
        toast.success(response?.message);
        refetch();
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : 'No server response',
        {
          containerId: 'parentToast',
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
    }
  };

  //   const onDeleteDialogClick = async () => {
  //     try {
  //       const response = await deleteRequest({ id: currentItem._id }).unwrap()
  //       if (response.success) {
  //         closeDialog()
  //         toast.success(response?.message)
  //       }
  //     } catch (error) {
  //       toast.error(
  //         error?.data?.message ? error?.data?.message : "No server response",
  //         {
  //           closeOnClick: true,
  //           toastId: 12,
  //           autoClose: 1000
  //         }
  //       )
  //     }
  //   }

  const onUpdatePackageClick = () => {
    // console.log("Editing");
    startEditingBulk();
    setIsOpenDrawer(true);

    // setDialogType("edit");
    // openDialog();
  };

  const onSort = (columnName) => {
    setSortQuery((prev) => ({
      field: columnName,
      dir:
        prev.field === columnName
          ? prev.dir === 'default'
            ? 'asc'
            : prev.dir === 'asc'
              ? 'dsc'
              : prev.dir === 'dsc' && 'asc'
          : 'asc',
    }));
  };

  useEffect(() => {
    if (state?.success) {
      toast.success(state.message);
    }
  }, [state]);

  useEffect(() => {
    if (error) {
      if (error?.status === 403) {
        localStorage.clear({});
        navigate('/login', { state: { expired: true } });
      }
    }
  }, [error, navigate]);
  return (
    <div className=" flex flex-col gap-1 bg-white shadow-lg">
      <Filters setFilters={setFilters} filters={filters} />
      <ToastContainer autoClose={1000} />
      <Drawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
        <UpdateContent
          isLoading={false}
          onSubmitBulkUpdate={onSubmitBulkUpdate}
          setIsOpen={setIsOpenDrawer}
        />
      </Drawer>
      {isDeleting ? (
        <DeleteDialog
          //   isLoading={isLoadingDeleteMt}
          //   onSubmitForm={onDeleteDialogClick}
          handleClose={closeDialog}
          open={open}
        />
      ) : (
        <Dialog isOpen={open} handleClose={closeDialog}>
          add
        </Dialog>
      )}
      <TableTopHeader
        updateBtnTitle={'Update Bulk Packages'}
        updateBtn={true}
        onUpdateBtnClick={onUpdatePackageClick}
        selectedRow={selectedRow}
        onRefreshClick={onRefreshClick}
        searchTitle={'Title'}
        searchTitleTwo={'Tracking ID'}
        // addBtn={true}
        // addBtnTitle={"Create Work Order"}
        refreshBtn={true}
        // onAddClick={onAddClick}
        twoSearchField={true}
        queryOne={searchTitle}
        queryTwo={searchTrackingId}
        setQuery={setSearchTitle}
        setQueryTwo={setSearchTrackingId}
      />
      {/* table wrapper */}
      <div className="flex flex-col min-h-[25em] justify-between px-2">
        {/* table head and body wrapper */}
        <table className="table-fixed border-collapse w-[100%] max-w-[100%] whitespace-nowrap">
          <TableHeader
            columns={columns}
            onSort={onSort}
            sortQuery={sortQuery}
            highlight={true}
          />
          <TableBody
            highlight={true}
            // hover={false}
            showEditButton={true}
            showDeleteButton={true}
            // showViewButton={true}
            onViewItem={onViewItemClick}
            onEditItem={onEditItemClick}
            onDeleteItem={onDeleteItemClick}
            columns={columns}
            currentTableData={getFilteredData}
            isLoading={isLoading || isFetching}
            // isLoading={true}
            selectedRows={selectedRow} // Pass selected rows state
            handleRowSelect={handleRowSelect}
          />
        </table>
        <TableFooter
          isLoading={isLoading || isFetching}
          currentPage={currentPage}
          pageSize={pageSize}
          currentTableData={getFilteredData}
          setCurrentPage={goToPage}
          setPageSize={setItemsPerPage}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );
};

export default PackageTable;
