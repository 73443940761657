import React, { useState } from 'react';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// flex: 1,

//   flexBasis: "10%",
//   fontSize: "0.875rem",
//   display: "flex",
//   alignItems: "center",
//   gap: "0.3em",
//   cursor: (direction === "asc" || direction === "dsc") && "pointer",
//   ":hover": {
//     ...(!focusfield && {
//       ".upIcon": {
//         opacity: "1",
//         // color: "#16827d",
//       },
//     }),
//   },
//   ".upIcon,.downIcon": {
//     opacity: "0.3",
//     color: "#16827d",
//   },
//   ...(focusfield && {
//     ...(direction === "asc" && {
//       ".upIcon": {
//         opacity: "1",
//         color: "#16827d",
//       },
//     }),
//     ...(direction === "dsc" && {
//       ".downIcon": {
//         opacity: "1",
//         color: "#16827d",
//       },
//     }),
//   }),

const TableHeader = ({ columns, onSort, sortQuery, highlight = false }) => {
  const [hover, setHover] = useState(false);
  return (
    // table head
    <thead className=" flex flex-col bg-slate-100 py-4">
      {/* table row */}
      <tr className="flex items-center">
        {highlight && <th className="w-8"></th>}
        {columns.map((item, index) => {
          // table heading
          return item.sorting ? (
            <th
              onClick={() => onSort(item.key)}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              key={index}
              className={`flex-1 border-r-2 border-l-2
              flex text-[0.875rem] items-center gap-2 justify-center   ${
                item.styles
              }
               ${sortQuery.dir === 'asc' && 'cursor-pointer'}
               ${sortQuery.dir === 'dsc' && 'cursor-pointer'}
              ${
                sortQuery.field === item.key &&
                sortQuery.dir === 'dsc' &&
                '[&_#downIcon]:opacity-[1]'
              }
              ${
                sortQuery.field === item.key &&
                sortQuery.dir === 'asc' &&
                '[&_#upIcon]:opacity-[1]'
              }
                 `}
            >
              <span>{item.title}</span>
              <div className="flex items-center">
                <FaArrowUpLong
                  id="upIcon"
                  className={`opacity-40 
                  
                  `}
                />
                <FaArrowDownLong id="downIcon" className={`opacity-40 `} />
              </div>
            </th>
          ) : (
            <th
              className={`${item.styles} flex-1 border-r-2 border-l-2`}
              key={index}
            >
              {item.title}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
