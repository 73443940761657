import React from 'react';

const Drawer = ({ children, isOpen, setIsOpen }) => {
  return (
    <main
      className={
        ' fixed overflow-hidden  z-[200] bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
        (isOpen
          ? ' transition-opacity opacity-100 duration-300 translate-x-0  '
          : ' transition-all delay-500 opacity-0 translate-x-full  ')
      }
    >
      <section
        className={
          ' w-screen max-w-md right-0 absolute bg-white h-full shadow-xl delay-400 duration-300 ease-in-out transition-all transform  ' +
          (isOpen ? ' translate-x-0 ' : ' translate-x-full ')
        }
      >
        <article className="relative w-screen max-w-md    flex flex-col space-y-6  h-full">
          <header className="p-4 font-bold text-lg ">
            {' '}
            Update Bulk Workorders
          </header>
          {children}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
};

export default Drawer;
