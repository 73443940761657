import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { FiChevronDown, FiCheck } from 'react-icons/fi';
import { BsSquare, BsCheckSquare } from 'react-icons/bs';

const ColumnSelector = ({ columns, columnVisibility, setColumnVisibility }) => {
  // Filter columns to exclude alwaysVisible columns and include only visible: true
  const toggleableColumns = columns.filter(
    (column) => !column.alwaysVisible && column.visible !== false
  );

  const handleSelectAll = () => {
    setColumnVisibility((prev) => {
      const newVisibility = { ...prev };
      toggleableColumns.forEach((col) => {
        newVisibility[col.key || col.name] = true;
      });
      return newVisibility;
    });
  };

  const handleDeselectAll = () => {
    setColumnVisibility((prev) => {
      const newVisibility = { ...prev };
      toggleableColumns.forEach((col) => {
        newVisibility[col.key || col.name] = false;
      });
      // Preserve alwaysVisible columns' state
      columns.forEach((col) => {
        if (col.alwaysVisible) {
          newVisibility[col.key || col.name] = true;
        }
      });
      return newVisibility;
    });
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
              group flex items-center justify-between px-4 py-2.5 text-sm font-medium rounded-lg border border-gray-200
              bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500
              transition-all duration-150 ${open ? 'ring-2 ring-blue-500 border-blue-500' : ''}
              shadow-sm hover:shadow-md
            `}
          >
            <span className="text-gray-700">Columns</span>
            <FiChevronDown
              className={`ml-2 transition-transform duration-200 ${
                open ? 'transform rotate-180 text-blue-500' : 'text-gray-400'
              }`}
            />
          </Popover.Button>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute right-0 z-20 mt-2 w-64 origin-top-right rounded-lg bg-white shadow-xl ring-1 ring-gray-200 focus:outline-none overflow-hidden">
              <div className="p-1">
                <div className="px-3 py-2.5 text-xs font-semibold text-gray-500 bg-gray-50 rounded-t-lg uppercase tracking-wider">
                  Toggle Columns
                </div>
                <div className="max-h-96 overflow-y-auto">
                  {toggleableColumns.map((column) => (
                    <div
                      key={column.key || column.name}
                      className={`flex items-center px-3 py-2.5 text-sm rounded-md cursor-pointer transition-colors duration-150 ${
                        columnVisibility[column.key || column.name]
                          ? 'bg-blue-50'
                          : 'hover:bg-gray-50'
                      }`}
                      onClick={() => {
                        setColumnVisibility((prev) => ({
                          ...prev,
                          [column.key || column.name]:
                            !prev[column.key || column.name],
                        }));
                      }}
                    >
                      {columnVisibility[column.key || column.name] ? (
                        <BsCheckSquare
                          size={17}
                          className="flex-shrink-0 text-blue-500 mr-3 text-base"
                        />
                      ) : (
                        <BsSquare
                          size={17}
                          className="flex-shrink-0 text-gray-400 mr-3 text-base"
                        />
                      )}
                      <span className="flex-1 text-gray-700 truncate">
                        {column.title}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between px-3 py-2 border-t border-gray-200 bg-gray-50 rounded-b-lg">
                  <button
                    className="text-xs text-blue-600 hover:text-blue-800 font-medium"
                    onClick={handleSelectAll}
                  >
                    Select All
                  </button>
                  <button
                    className="text-xs text-blue-600 hover:text-blue-800 font-medium"
                    onClick={handleDeselectAll}
                  >
                    Deselect All
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ColumnSelector;
