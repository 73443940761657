import React from 'react';
import _ from 'lodash';
import TableData from './TableData';
import { GoStarFill } from 'react-icons/go';

const TableRow = ({
  onEditItem,
  onDeleteItem,
  onViewItem,
  showEditButton,
  showDeleteButton,
  showViewButton,
  data,
  columns,
  itemNumber,
  isSelected,
  handleRowSelect,
  hover,
  highlight,
}) => {
  // console.log({ fav: data.favourite });
  return (
    <tr
      onClick={() => handleRowSelect(data._id)}
      className={`transition-all duration-75 flex border-b-2 py-[0.4em] ${
        hover && 'hover:bg-sky-200 cursor-pointer'
      }  hover:shadow-[0px 3px 12px -5px rgba(0,0,0,0.3)] text-[0.9rem] font-semibold   ${
        isSelected && 'bg-sky-200'
      }`}
    >
      {highlight && (
        <td className="flex items-center w-8 ps-[0.1em]">
          {data?.favourite ? (
            <GoStarFill size={17} className="text-cyan-600" />
          ) : (
            ''
          )}
        </td>
      )}
      {columns?.map((column, index) => {
        return (
          <TableData
            key={index}
            index={index}
            column={column}
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
            onViewItem={onViewItem}
            showEditButton={showEditButton}
            showDeleteButton={showDeleteButton}
            showViewButton={showViewButton}
            data={data}
            itemNumber={itemNumber}
          />
        );
      })}
    </tr>
  );
};

export default TableRow;
