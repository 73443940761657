import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Label from './Label';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Webcam from 'react-webcam';
import { SlPicture } from 'react-icons/sl';
import { LuCamera } from 'react-icons/lu';

const MultiPicUploadForUpdate = ({ fieldlabel, ...props }) => {
  const [field, { value }, { setValue }] = useField(props);
  const [hover, setHover] = useState(null);
  const isFirstRender = useRef(true);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const webcamRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);

  // console.log({ value })
  const [files, setFiles] = useState([]);

  const handleFile = (e) => {
    let file = e.target.files;
    if (file.length + value.length > 3) {
      toast.error('Maximum 3 images allowed');
      return;
    }
    for (let i = 0; i < file.length; i++) {
      const fileType = file[i]['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (validImageTypes.includes(fileType)) {
        setFiles((old) => [...old, file[i]]);
      } else {
        toast.error('Please upload only image files.');
      }
    }
  };

  const removeImage = (i) => {
    setFiles(value.filter((x, index) => index !== i));

    setHover(null);
  };

  const captureImage = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `captured-${Date.now()}.png`, {
          type: 'image/png',
        });
        if (file && files.length > 2) {
          setIsCameraOpen(false);
          toast.error('Maximum 3 images allowed');
          return;
        }
        setFiles((old) => [...old, file]);
        setIsCameraOpen(false);
      });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      setFiles(value);
      isFirstRender.current = false;
      return;
    }
    setValue(files);
  }, [files, setValue, value]);

  return (
    <div className="flex flex-col items-start gap-[0.1em] w-[100%]">
      <ToastContainer />
      <Label label={fieldlabel} />
      <div className="flex flex-col w-[100%]">
        <div className="rounded-lg bg-gray-50 w-full">
          <div className="m-4">
            <div className="border-4 flex flex-row">
              <div className="flex items-center justify-center w-full">
                <label className="flex cursor-pointer flex-col w-full h-32 border-2 rounded-md border-dashed hover:bg-blue-200 hover:border-blue-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <SlPicture size={30} />
                    <p className="pt-1 text-sm tracking-wider text-gray-700 group-hover:text-gray-600">
                      Select a photo
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={handleFile}
                    className="opacity-0"
                    multiple="multiple"
                    //   name="files[]"
                  />
                </label>
              </div>
              <div className="flex items-center justify-center w-full">
                <label
                  className="flex cursor-pointer flex-col w-full h-32 border-2 rounded-md border-dashed hover:bg-blue-200 hover:border-blue-300"
                  onClick={() => setIsCameraOpen(true)}
                >
                  <div className="flex flex-col items-center justify-center pt-7">
                    <LuCamera size={30} />
                    <p className="pt-1 text-sm tracking-wider text-gray-700 group-hover:text-gray-600">
                      Open camera
                    </p>
                  </div>
                </label>
              </div>
            </div>
            {/* Camera Modal */}
            {isCameraOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
                <div className="bg-white p-4 rounded-lg">
                  <Webcam
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    className="w-full h-64"
                    videoConstraints={{
                      width: 1280, // Increase width
                      height: 720, // Increase height
                      facingMode: 'user', // Use 'environment' for the back camera
                    }}
                  />
                  <div className="flex justify-between mt-2">
                    <button
                      className="px-4 py-2 bg-red-500 text-white rounded-md"
                      onClick={() => setIsCameraOpen(false)}
                    >
                      Close
                    </button>
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded-md"
                      onClick={captureImage}
                    >
                      Capture
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-wrap gap-2 mt-2">
              {files.length > 0 && (
                <div className="grid grid-cols-3 gap-4 mt-4">
                  {/* {files.map((file, index) => (
                              <div key={index} className="relative group">
          
                                <img
                                  className="w-24 h-24 rounded-md object-cover shadow-md"
                                  alt="pic"
                                  src={URL.createObjectURL(file)}
                                />
          
                              
                                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md">
          
                                  <button
                                    className="px-3 py-1 text-xs bg-white text-black rounded-md mb-1 hover:bg-gray-200 transition-all"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setPreviewImage(URL.createObjectURL(file));
                                    }}
                                  >
                                    Preview
                                  </button>
                                  <button
                                    className="px-3 py-1 text-xs bg-red-600 text-white rounded-md hover:bg-red-700 transition-all"
                                    onClick={() => removeImage(index)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            ))} */}

                  {files.length > 0 &&
                    files.map((file, index) => (
                      <div
                        key={index}
                        className="relative flex flex-col items-center"
                      >
                        {typeof file === 'string' ? (
                          <img
                            className="w-20 h-20 rounded-md object-cover"
                            alt="pic"
                            src={`${process.env.REACT_APP_URL}/uploads/images/${file}`}
                          />
                        ) : (
                          <img
                            className="w-20 h-20 rounded-md object-cover"
                            alt="pic"
                            src={URL.createObjectURL(file)}
                          />
                        )}

                        <div className="flex gap-2 mt-2">
                          <button
                            className="px-2 py-1 text-xs bg-blue-500 text-white rounded-md"
                            onClick={(e) => {
                              e.preventDefault();
                              setPreviewImage(URL.createObjectURL(file));
                            }}
                          >
                            Preview
                          </button>
                          <button
                            className="px-2 py-1 text-xs bg-red-500 text-white rounded-md"
                            onClick={() => removeImage(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>

            {previewImage && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
                <div className="bg-white p-4 rounded-lg relative">
                  <img
                    src={previewImage}
                    alt="preview"
                    className="w-[300px] h-auto rounded-md"
                  />
                  <button
                    className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full"
                    onClick={() => setPreviewImage(null)}
                  >
                    ✕
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiPicUploadForUpdate;
