import { calculateTotalWeight, DateFormat } from '../../../../utils/format';

export const columns = [
  {
    title: 'Title',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'title',
    visible: true,
    wrap: true,
  },
  {
    title: 'Type',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'type',
    visible: true,
  },
  {
    title: 'Tracking ID',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'trackingId',
    visible: true,
    alwaysVisible: true,
  },
  {
    title: 'Email',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'email',
    visible: true,
  },
  {
    title: 'Weight (lbs)',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'packages',
    render: (value) => calculateTotalWeight(value), // Custom render function
    visible: true,
    wrap: true,
  },
  {
    title: 'Status',
    styles: 'min-w-[10rem]',
    sorting: false,
    key: 'status',
    render: (value) => {
      const statusConfig = {
        placed: {
          bg: 'bg-orange-200',
          text: 'text-orange-700',
          label: 'Placed',
        },
        shipped: {
          bg: 'bg-emerald-200',
          text: 'text-emerald-700',
          label: 'Shipped',
        },
        default: {
          bg: 'bg-blue-200',
          text: 'text-blue-700',
          label: 'Delivered',
        },
      };

      const { bg, text, label } = statusConfig[value] || statusConfig.default;

      return (
        <div className="w-full">
          <span
            className={`${bg} ${text} px-2 py-1 rounded-md whitespace-nowrap inline-block`}
          >
            {label}
          </span>
        </div>
      );
    },
    visible: true,
  },
  {
    title: 'Cargo Available Status',
    styles: 'min-w-[15rem]',
    sorting: false,
    key: 'cargoAvailableStatus',
    render: (value) => {
      const statusConfig = {
        inProcessOfCheckIn: {
          bg: 'bg-slate-200',
          text: 'text-slate-700',
          label: 'In Process Of Check In',
        },
        receivedAtWarehouse: {
          bg: 'bg-green-200',
          text: 'text-green-700',
          label: 'Received At Warehouse',
        },
        default: {
          bg: 'bg-blue-200',
          text: 'text-blue-700',
          label: 'Ready for Pick up',
        },
      };

      const { bg, text, label } = statusConfig[value] || statusConfig.default;

      return (
        <div className="w-full">
          <span
            className={`${bg} ${text} px-2 py-1 rounded-md whitespace-nowrap inline-block`}
          >
            {label}
          </span>
        </div>
      );
    },
    visible: true,
  },
  {
    title: 'Departure Date',
    styles: 'min-w-[13rem]',
    sorting: false,
    key: 'departureDate',
    render: (value) => (value ? DateFormat(value, false) : ''),
    visible: true,
  },
  {
    title: 'Arrival Date',
    styles: 'min-w-[13rem]',
    sorting: false,
    key: 'arrivalDate',
    render: (value) => (value ? DateFormat(value, false) : ''),
    visible: true,
  },
  {
    title: 'Last Free Day',
    styles: 'min-w-[13rem]',
    sorting: false,
    key: 'last_free_day',
    render: (value) => (value ? DateFormat(value, false) : ''),
    visible: true,
  },
  {
    title: 'Is Cost Calculator used',
    styles: 'min-w-[10rem]',
    sorting: false,
    key: 'want_to_use_calculator',
    render: (value, data) => {
      // console.log(value);
      return value ? 'Yes' : 'No';
    },
    visible: true,
  },
  {
    title: 'Import Fee',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'import_fee',
    render: (value) => (value ? `${value} USD` : ''),
    visible: true,
  },
  {
    title: 'Handling Fee',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'handling_fee',
    render: (value) => (value ? `${value} USD` : ''),
    visible: true,
  },
  {
    title: 'Duty',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'duty',
    render: (value) => (value ? `${value} USD` : ''),
    visible: true,
  },
  {
    title: 'Total Cost',
    styles: 'min-w-[12rem]',
    sorting: false,
    key: 'total_cost',
    render: (value) => (value ? `${value} USD` : ''),
    visible: true,
  },

  {
    title: 'Action(s)',
    styles: 'min-w-[10rem]',
    sorting: false,
    key: null,
    name: 'action',
    visible: true,
    alwaysVisible: true,
  },
];
