import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthRoutes = ({ redirect = '/dashboard', children }) => {
  if (localStorage.getItem('admin')) {
    //if user logged in and have access token it will always redirect to dashboard
    //for login route and all other (*) routes
    //Any other route if have token will go to Dash
    return <Navigate to={redirect} replace />;
  } else if (children) {
    return <Navigate to={redirect} replace />;
  } else {
    return <Outlet />;
  }
};

export default AuthRoutes;
